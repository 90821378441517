@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,400italic,700,700italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,700&subset=latin,latin-ext");


@import "bootstrap_theme/bootstrap";
@import "font-awesome";
@import "sweetalert";
@import "hover/hover";
@import "slick/slick";
@import "slick/slick-theme";
@import "partials/variables";
@import "partials/labelauty";
@import "partials/mixins";


@import "partials/base";
@import "partials/navbar";
@import "partials/objects";
@import "partials/homepage";
@import "partials/otherpages";
@import "partials/banner";
