.TopSlider {
    width: 100%;
    overflow: hidden;

    background-color: #303741;
    background-image: url(/img/pages/stripe_texture_dark.png);
    padding: 10px 0px;

    &__item {

    }

    &__image {
        margin: 0 auto;
        max-height: 300px;
    }

    &__dots {
        position: relative;

    }

    &--larger {

        padding: 0px;

        .TopSlider__image {
            margin: 0 auto;
            max-height: 320px;
        }
    }
}

.PageBox {
    position: relative;

    &__virgule {
        position: absolute;
        left: -58px;
        top: 20px;
    }

    &__background {
        position: absolute;
        left: -237px;
        top: -25px;
    }

    &__content {

        min-height: 350px;
        background-color: white;
        position: relative;
        padding: 8px 30px 30px 30px;
    }

    &__title {
        padding-left: 60px;
        font-size: 1.9em;
        font-weight: 600;

    }

    &__subtitle {
        color: #ce2027;
        font-weight: 600;
        font-size: 1.3em;
        padding-left: 170px;
        margin-top: -10px;

        @include respond-to(xs) {
            padding-left: 30px;
        }
    }

    &__text {
        margin-top: 50px;
        font-weight: 600;
        line-height: 1.8em;
    }

    &__image {
        position: relative;

        &--centered {
            left: 50%;
            transform: translate(-50%,0);
        }
    }
}