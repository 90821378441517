@media (max-width: 992px) {
    .padded-container {
        padding-left: 0px !important;
    }


}


@media (min-width: 992px) {
    .padded-container {
        padding-left: 50px !important;
    }
}


.padded-container {
    padding-left: 100px;
    font-weight: 600;


    .heading {
        margin-top: 30px;
    }
}

.other-page {
    .title {
        margin-left: -50px;
        h1 {

            font-weight: 600;
            margin-bottom: 5px;
            margin-top: -6px;
            font-size: 1.9em;
        }

        @include respond-to(md) { margin-left: 0px; }
        @include respond-to(sm) { margin-left: 0px; }
        @include respond-to(xs) { margin-left: 0px; }
    }

    .subtitle h2 {
        font-weight: 400;
        color: $red;
        font-size: 1.3em;

        margin: 5px 0px;
    }

    .gallery-spacer {
        margin-top: 30px;
    }
}

.page-body {
    margin-top: 30px;

    h3 {
        color: $red;
        font-weight: 400;
        margin-top: 0px;


    }
}

.red-divider {
    width: 20%;
    height:1px;
    border: 1px solid $red;
    background-color: $red;
    margin-top: 13px;
    margin-bottom: -10px;
}

.Partner {

    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;

    &__logo {
        width: 100%;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: none repeat scroll 0 0 rgb(132, 134, 135);
        opacity: 0;
        -webkit-transition: visibility 0.3s, opacity 0.3s linear;
        transition: visibility 0.3s, opacity 0.3s linear;
        visibility: hidden;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &:hover &__overlay {

        visibility: visible;
        opacity: 1;
    }
}

.Partner-OverlayLink {
    position: relative;

    display: flex;
    justify-content: left;
    align-self: center;

    &__icon {

        display: inline-block;


        img {
            width: 50px;
        }

    }

    &__text {
        padding-left: 20px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.1em;
        color: white;

        ul {
            padding-left: 20px;
        }
    }
}

#map-div {
    margin-top: 50px;
    width: 100%;
    height: 400px;
}

.Contact {
    &__title {
        font-weight: 600;
        color: $red;
        font-size: 1.2em;

    }

    &>div {
        padding-left: 20px;
        position: relative;
        margin-bottom: 5px;
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.eshop-frame {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
}

.salon-wrapper {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 15px;
    padding-bottom: 10px;


}

.salon-wrapper .salon-title {
    font-size: 1.7em;
    font-weight: 400;
    margin-bottom: 10px;
}

.salon-wrapper .salon-image {

    max-height: 100%;
}
