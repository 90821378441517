.top-photo-container {
    position: relative;

    .top-logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%,0%);
        bottom: 17px;
        width: 33%;

        @include respond-to(sm) {
            width: 45%;
        }

        @include respond-to(xs) {
            width: 65%;
        }
    }
}

#home-text {

    font-weight: 600;
    line-height: 1.6em;

}